/* Core */
@use "./container";

/* Components */
@use "src/theme/components/item/btn-item";

/* Tools */
@use "./calendar";
@use "./shop";

/* Libraries */
@use "node_modules/img-comparison-slider/dist/styles.css";

/* Custom */

*[hidden] {
  display: none !important;
}

pipeline-no-entries-card {
  margin-top: 15vh;
}

ion-popover {
  ion-list {
    ion-item {
      cursor: pointer;
    }
  }
}