ion-card,
ion-item,
ion-list {
    --background: rgba(var(--ion-card-background-rgb), 0.85) !important;
    background: rgba(var(--ion-card-background-rgb), 0.85) !important;
    --border-radius: 10px !important;
    border-radius: 10px !important;
}

ion-card {
    border: 1px solid var(--ion-color-medium) !important;
}

ion-card ion-card {
    border-width: 0px !important;
}

/*
ion-card {
    backdrop-filter: blur(10px) !important;
    -webkit-backdrop-filter: blur(10px) !important;
}
*/