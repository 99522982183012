html {

    app-project {
        ion-content {

            .style-helper-element {
                content: '';
                position: fixed;
                top: 0px;
                left: 0px;
                background-size: contain;
                z-index: 9;
                background-repeat: no-repeat;
                background-position: top left;
        
                @media screen and (max-width: 768px) {
                    position: absolute;
                }
        
                &.a {
                    width: 300px;
                    height: 200px;
                    left: 75%;
                    top: 40%;
                    background-image: url(../../assets/img/getgenius/light/color_bucket.webp);
                    
                    @media screen and (max-width: 768px) {
                        top: 25%;
                        left: 65%;
                        width: 150px;
                        height: 100px;
                    }
                }
        
                &.b {
                    width: 150px;
                    height: 150px;
                    left: 65%;
                    top: 35%;
                    background-image: url(../../assets/img/getgenius/light/cube.webp);
        
                    @media screen and (max-width: 768px) {
                        left: 80%;
                        width: 50px;
                        height: 50px;
                        top: 2.5%;    
                    }
                }
        
                &.c {
                    width: 35vw;
                    height: 40vh;
                    left: 60%;
                    top: 65%;
                    background-image: url(../../assets/img/getgenius/light/gwd_visual.webp);
                    
                    @media screen and (max-width: 768px) {
                        display: none;
                    }
                }
        
            }

            &.look_and_feel {

                .style-helper-element.a {
                    background-image: url(../../assets/img/getgenius/light/colors_visual.webp);
                    left: 57.5%;
                    width: 35vw;
                    height: 40vh;
                }

                .style-helper-element.b,
                .style-helper-element.c {
                    display: none !important;
                }
            }

            @media screen and (max-width: 768px) {

                > .container {
                    > ion-grid {
                        margin-top: 5vh;
                    }
                }

                .style-helper-element.a {
                    top: 25%;
                    left: 65%;
                    width: 150px;
                    height: 100px;
                }

                &.look_and_feel {
                    .style-helper-element.b {
                        display: block !important;
                    }
                }
            }

        }
    }

    &.dark {
        app-project-setup {
            ion-content {

                .style-helper-element.a {
                    background-image: url(../../assets/img/getgenius/dark/color_bucket.webp);
                }

                .style-helper-element.b {
                    background-image: url(../../assets/img/getgenius/dark/cube.webp);
                }

                .style-helper-element.c {
                    background-image: url(../../assets/img/getgenius/dark/gwd_visual.webp);
                }

                &.look_and_feel {
                    .style-helper-element.a {
                        background-image: url(../../assets/img/getgenius/dark/colors_visual.webp);
                    }
                }

            }
        }
    }
    
}