@use "src/theme/components/grid/peopleGrid";
@use "src/theme/components/grid/socialGrid";
@use "src/theme/components/grid/storiesGrid";

.resizable-col {
    position: relative;
    transition: all 0.3s ease;
}

.resize-handle {
    position: absolute;
    width: 8px;
    cursor: col-resize;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.2s;
    background: rgba(0, 0, 0, 0.1);
}

.resize-handle:hover {
    opacity: 1;
}

.resize-handle.right {
    right: 0;
    top: 0;
    bottom: 0;
}

.resize-handle.bottom {
    bottom: 0;
    left: 0;
    right: 0;
    height: 8px;
    width: auto;
    cursor: row-resize;
}

.resizable-col:hover .resize-handle {
    opacity: 0.5;
}

/*
.resize-handle {
    position: absolute;
    top: 0;
    width: 20px;
    height: 100%;
    cursor: pointer;
    z-index: 100;
    transition: all 0.2s ease;
}

.resize-handle:hover {
    background-color: rgba(0, 123, 255, 0.1);
}

.resize-handle::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 6px;
    height: 30px;
    background-color: #007bff;
    border-radius: 3px;
    opacity: 0;
    transition: opacity 0.2s ease;
}

.resize-handle:hover::after {
    opacity: 0.7;
}

.resize-handle.left {
    left: 0;
}

.resize-handle.right {
    right: 0;
}

.resize-handle.left::after {
    transform: translate(-50%, -50%) rotate(90deg);
}

.resize-handle.right::after {
    transform: translate(-50%, -50%) rotate(90deg);
}

ion-col {
    transition: flex 0.3s ease, max-width 0.3s ease, width 0.3s ease;
}

@media (max-width: 768px) {
    .resize-handle {
        width: 15px;
    }

    .resize-handle::after {
        width: 4px;
        height: 20px;
    }
}

.resize-handle:active::after {
    opacity: 1;
    background-color: #0056b3;
}

.resize-handle.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.resize-handle.disabled:hover::after {
    opacity: 0;
}
*/