html {
    app-forgot-password {

        ion-header {
            ion-toolbar {
                ion-back-button,
                ion-title {
                    --color: var(--ion-color-white);
                    color: var(--ion-color-white);
                }
            }    
        }

        form::before {
            background-size: 65% !important;
        }
    }
}