@media screen and (min-width: 1024px) {

    :root {
        --pipeline-container-width: 1000px !important;
    }

    .container {
        max-width: var(--pipeline-container-width) !important;
        margin: auto !important;
        left: 0px !important;
        right: 0px !important;
        display: block !important;
        float: none !important;
    }

}

/* 1280px */
@media screen and (min-width: 1280px) {
    :root {
        --pipeline-container-width: 1200px !important;
    }
}

/* HD-ready */
@media screen and (min-width: 1366px) {
    :root {
        --pipeline-container-width: 1340px !important;
    }
}

/* Most likely 14 inch */
@media screen and (min-width: 1440px) {
    :root {
        --pipeline-container-width: 1400px !important;
    }
}

/* Most likely 16 inch */
@media screen and (min-width: 1680px) {
    :root {
        --pipeline-container-width: 1660px !important;
    }
}

/* Full HD */
@media screen and (min-width: 1920px) {
    :root {
        --pipeline-container-width: 1800px !important;
    }
}

/* 2K */
@media screen and (min-width: 2560px) {
    :root {
        --pipeline-container-width: 2080px !important;
    }
}

/* > 2K */
@media screen and (min-width: 2600px) {
    :root {
        --pipeline-container-width: 2380px !important;
    }
}

/* Custom Ultra-wide mode */
@media screen and (min-width: 2880px) {
    :root {
        --pipeline-container-width: 2700px !important;
    }
}