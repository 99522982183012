html {
    
    app-create-assets,
    app-create-creatives,
    app-create-media {
    
        .style-helper-element {
            content: '';
            position: fixed;
            top: 0px;
            left: 0px;
            background-size: contain;
            z-index: 0;
            background-repeat: no-repeat;
            background-position: top left;
    
            @media screen and (max-width: 768px) {
                position: absolute;
            }
    
            &.a {
                width: 30vw;
                height: 30vw;
                left: calc(100% - 25vw);
                top: 25%;
                background-image: url(../../assets/img/getgenius/light/bucket.webp);
                
                @media screen and (max-width: 768px) {
                    top: 2.5%;
                }
            }
    
            &.b {
                width: 15vw;
                height: 15vw;
                left: 27.5%;
                top: calc(100% - 17.5vw);
                background-image: url(../../assets/img/getgenius/light/cube.webp);
    
                @media screen and (max-width: 768px) {
                    left: 90%;
                }
            }
    
        }

    }

    &.dark {

    }

    &.light {

    }

}