ion-app {

  ion-modal {

    &.fullscreenModal {
      --width: 100vw;
      --height: 100vh;

      --pipeline-modal-height: 100vh;
      --pipeline-modal-width: 100vw;
      --pipeline-modal-wrapper-height: 100vh;
      --pipeline-modal-wrapper-width: 100vw;

      .ion-page {
        --width: 100vw;
        width: 100vw;
        --height: 100vh;
        height: 100vh;
        left: 0px;
        top: 0px;

        .container-fluid {
          max-width: 100% !important;
          width: 100% !important;
        }
      }
    }

    &.defaultModal,
    &.mediumModal,
    &.smallModal {

      @media screen and (max-width: 768px) {
        --height: calc(100% - var(--ion-safe-area-top));
      }

      @media screen and (min-width: 768px) {
        .ion-page {
          --width: 100vw;
          width: 100vw;
          --height: 100vh;
          height: 100vh;
          left: 0px;
          top: 0px;
        }
      }

      @media screen and (min-width: 1024px) {
        --max-width: 100vw;
        --width: 100vw;
        --height: 100%;
        width: 100vw;
        left: auto;
        right: 0px;

        .ion-page {
          width: calc(100vw - var(--side-width));
          height: 100vh;
          left: auto;
          right: 0px;
          bottom: 0px;
        }
      }

      @media screen and (min-width: 1366px) {
        background: transparent !important;
        --background: transparent !important;

        .ion-page {
          --pipeline-modal-offset-height: calc((100vh - var(--pipeline-modal-height)) / 2);
          --pipeline-modal-offset-width: calc((100vw - var(--pipeline-modal-width)) / 2);

          --height: var(--pipeline-modal-height);
          --border-radius: 20px;
          border-radius: 20px;
          width: calc(var(--pipeline-modal-width) - var(--side-width));
          --width: calc(var(--pipeline-modal-width) - var(--side-width));
          --max-width: calc(var(--pipeline-modal-width) - var(--side-width));
          height: var(--pipeline-modal-height);
          left: var(--pipeline-modal-offset-width);
          top: var(--pipeline-modal-offset-height);
          overflow: hidden;
        }
      }

      @media screen and (min-width: 1680px) {
        .ion-page {
          height: var(--pipeline-modal-height);
          left: calc((100vw - var(--pipeline-modal-width)) / 2);
          top: calc((100vh - var(--pipeline-modal-height)) / 2);
          width: calc(var(--pipeline-modal-width) - var(--side-width));
          --width: calc(var(--pipeline-modal-width) - var(--side-width));
          --max-width: calc(var(--pipeline-modal-width) - var(--side-width));
        }
      }
    }

    &.defaultModal {
      --pipeline-modal-height: 90vh;
      --pipeline-modal-width: 90vw;
      --pipeline-modal-wrapper-width: 100vw;
      --pipeline-modal-wrapper-height: 100vh;

      @media screen and (min-width: 1366px) {
        .ion-page {
          --pipeline-modal-height: 90vh;
          --pipeline-modal-width: 95vw;
        }
      }

      @media screen and (min-width: 1680px) {
        .ion-page {
          --pipeline-modal-height: 90vh;
          --pipeline-modal-width: 85vw;
        }
      }

    }

    &.smallModal {

      @media screen and (min-width: 1366px) {
        .ion-page {
          --pipeline-modal-height: 40vh;
          --pipeline-modal-width: 40vw;
        }
      }

      @media screen and (min-width: 1680px) {
        .ion-page {
          --pipeline-modal-height: 40vh;
          --pipeline-modal-width: 40vw;
        }
      }

    }

    &.mediumModal {

      @media screen and (min-width: 1366px) {
        .ion-page {
          --pipeline-modal-height: 60vh;
          --pipeline-modal-width: 60vw;
        }
      }

      @media screen and (min-width: 1680px) {
        .ion-page {
          --pipeline-modal-height: 60vh;
          --pipeline-modal-width: 60vw;
        }
      }

    }

  }

  /* If logged in (sidebar is visible) */
  &.loggedin {

    ion-modal {

      &.defaultModal {

        @media screen and (min-width: 1024px) {
          --pipeline-modal-wrapper-height: 100%;
          --pipeline-modal-wrapper-width: calc(100vw - var(--side-width));


          height: var(--pipeline-modal-wrapper-height);
          --height: var(--pipeline-modal-wrapper-height);
          max-height: var(--pipeline-modal-wrapper-height);
          --max-height: var(--pipeline-modal-wrapper-height);

          width: var(--pipeline-modal-wrapper-width);
          --width: var(--pipeline-modal-wrapper-width);
          max-width: var(--pipeline-modal-wrapper-width);
          --max-width: var(--pipeline-modal-wrapper-width);
        }

      }

    }

    &.menu-position-bottom {
      ion-modal.defaultModal {
        height: calc(100vh - var(--side-width));
        --max-height: calc(100vh - var(--side-width));
        --height: calc(100vh - var(--side-width));
        --max-width: 100vw;
        --width: 100vw;
        width: 100vw;

        .ion-page {
          transform: translate(0px, calc(var(--side-width) - (1.5* var(--side-width)))) !important;
        }

      }
    }

    &.menu-position-top {
      ion-modal.defaultModal {
        top: var(--side-width);
        height: calc(100vh - var(--side-width));
        --max-height: calc(100vh - var(--side-width));
        --height: calc(100vh - var(--side-width));
        --max-width: 100vw;
        --width: 100vw;
        width: 100vw;

        .ion-page {
          transform: translate(0px, calc(var(--side-width) - (1.5* var(--side-width)))) !important;
        }

      }
    }

  }

  /* If window manager is enabled */
  &.has-windowmanager {

    ion-modal {
      --ion-backdrop-color: transparent !important;

      .ion-page {
        resize: both;
        box-shadow: 0px 0px 50px -15px var(--ion-color-medium);
        border: 1px solid var(--ion-color-medium);

        >ion-header {
          >ion-toolbar:first-child {
            cursor: move;
            border-bottom: 1px solid var(--ion-color-medium);
            user-select: none;
          }
        }
      }

    }
  }

}