:root {
  --pipeline-forgot-password-web-left-area-background-color: #232635;
  --pipeline-header-background-color: #fff;
  --pipeline-login-web-left-area-background-color: #232635;
  --pipeline-post-reader-background-color: #fff;
  --pipeline-register-web-left-area-background-color: #232635;
  --pipeline-sidebar-background-color: #A56B3D;
  --pipeline-sidebar-background-color-contrast: #fff;

  --ion-card-background: #fff !important;
  --ion-card-background-rgb: 255, 255, 255 !important;
  --ion-item-background: #fff !important;
  --ion-item-background-rgb: 255, 255, 255 !important;
  --ion-background-color: #f5f5f5 !important;
  --ion-background-color-rgb: 249, 249, 249 !important;
  --ion-statusbar-padding: 0px;
  --ion-toolbar-background: #f5f5f5 !important;

  --ion-color-primary: #A56B3D !important;
  --ion-color-primary-rgb: 165, 107, 61 !important;
  --ion-color-primary-contrast: #fff !important;
  --ion-color-primary-contrast-rgb: 255, 255, 255 !important;
  --ion-color-primary-shade: #845732 !important;
  --ion-color-primary-tint: #ba7e4c !important;

  --ion-color-secondary: #00c7aa;
  --ion-color-secondary-rgb: 225, 225, 0;
  --ion-color-secondary-contrast: #000;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #02b197;
  --ion-color-secondary-tint: #07ffda;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;
  /** success **/
  --ion-color-success: #00c7aa;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  /** medium **/
  --ion-color-medium: #d1d2d5;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  /** light **/
  --ion-color-light: #fff8f1;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #808289;
  --ion-color-white-tint: #9d9fa6;

  --ion-color-facebook: #3b5998;
  --ion-color-facebook-rgb: 59, 89, 152;
  --ion-color-facebook-contrast: #ffffff;
  --ion-color-facebook-contrast-rgb: 255, 255, 255;
  --ion-color-facebook-shade: #304778;
  --ion-color-facebook-tint: #4b70be;

  --ion-color-black: #151515;
  --ion-color-black-rgb: 15, 15, 15;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255, 255, 255;
  --ion-color-black-shade: #000000;
  --ion-color-black-tint: #444444;
}

img.inverted {
  filter: invert(100%);
}

.light.light_pipeline,
.light.light_pipeline body,
.light.light_pipeline.md,
.light.light_pipeline.ios,
.light.light_pipeline.md body,
.light.light_pipeline.ios body {
  --ion-background-color: #f5f5f5 !important;
  --ion-background-color-rgb: 249, 249, 249 !important;
  --ion-color-base: #e2d8ff !important;
  --ion-color-light: #e2d8ff !important;
  --ion-card-background: #e2d8ff !important;
  --ion-card-background-rgb: 255, 255, 255 !important;
  --ion-item-background: #e2d8ff !important;
}

html:not(.light) {
  --pipeline-sidebar-background-color: #161922 !important;
  --ion-card-background: #21202e !important;
  --ion-card-background-rgb: 33, 32, 46 !important;
  --ion-item-background: #161922 !important;
  --ion-item-background-rgb: 22, 25, 34 !important;
  --ion-background-color: #161922 !important;
  --ion-background-color-rgb: 22, 25, 34 !important;

  @media (prefers-color-scheme: dark) {

    img.inverted {
      filter: invert(0%) !important;
    }

    ion-content.ion-color-dark .post-page a,
    ion-content.ion-color-primary .post-page a {
      color: #000;
    }

    /*
   * Dark Colors
   * -------------------------------------------
   */
    body {
      --ion-color-primary: #A56B3D;
      --ion-color-primary-rgb: 165, 107, 61;
      --ion-color-primary-contrast: #fff;
      --ion-color-primary-contrast-rgb: 255, 255, 255;
      --ion-color-primary-shade: #845732;
      --ion-color-primary-tint: #ba7e4c;

      --ion-color-secondary: #00c7aa;
      --ion-color-secondary-rgb: 225, 225, 0;
      --ion-color-secondary-contrast: #000;
      --ion-color-secondary-contrast-rgb: 255, 255, 255;
      --ion-color-secondary-shade: #02b197;
      --ion-color-secondary-tint: #04e2c1;

      --ion-color-tertiary: #6a64ff;
      --ion-color-tertiary-rgb: 106, 100, 255;
      --ion-color-tertiary-contrast: #ffffff;
      --ion-color-tertiary-contrast-rgb: 255, 255, 255;
      --ion-color-tertiary-shade: #5d58e0;
      --ion-color-tertiary-tint: #7974ff;
      --ion-color-success: #2fdf75;
      --ion-color-success-rgb: 47, 223, 117;
      --ion-color-success-contrast: #000000;
      --ion-color-success-contrast-rgb: 0, 0, 0;
      --ion-color-success-shade: #29c467;
      --ion-color-success-tint: #44e283;
      --ion-color-warning: #ffd534;
      --ion-color-warning-rgb: 255, 213, 52;
      --ion-color-warning-contrast: #000000;
      --ion-color-warning-contrast-rgb: 0, 0, 0;
      --ion-color-warning-shade: #e0bb2e;
      --ion-color-warning-tint: #ffd948;
      --ion-color-danger: #ff4961;
      --ion-color-danger-rgb: 255, 73, 97;
      --ion-color-danger-contrast: #ffffff;
      --ion-color-danger-contrast-rgb: 255, 255, 255;
      --ion-color-danger-shade: #e04055;
      --ion-color-danger-tint: #ff5b71;
      --ion-color-dark: #f4f5f8;
      --ion-color-dark-rgb: 244, 245, 248;
      --ion-color-dark-contrast: #000000;
      --ion-color-dark-contrast-rgb: 0, 0, 0;
      --ion-color-dark-shade: #d7d8da;
      --ion-color-dark-tint: #f5f6f9;
      --ion-color-medium: #313131;
      --ion-color-medium-rgb: 65, 65, 65;
      --ion-color-medium-contrast: #000000;
      --ion-color-medium-contrast-rgb: 0, 0, 0;
      --ion-color-medium-shade: #212121;
      --ion-color-medium-tint: #373737;
      --ion-color-light: #101113;
      --ion-color-light-rgb: 34, 36, 40;
      --ion-color-light-contrast: #ffffff;
      --ion-color-light-contrast-rgb: 255, 255, 255;
      --ion-color-light-shade: #1e2023;
      --ion-color-light-tint: #383a3e;
      --ion-color-white: #ffffff;
      --ion-color-white-rgb: 255, 255, 255;
      --ion-color-white-contrast: #000000;
      --ion-color-white-contrast-rgb: 0, 0, 0;
      --ion-color-white-shade: #808289;
      --ion-color-white-tint: #9d9fa6;
      --ion-color-facebook: #3b5998;
      --ion-color-facebook-rgb: 59, 89, 152;
      --ion-color-facebook-contrast: #ffffff;
      --ion-color-facebook-contrast-rgb: 255, 255, 255;
      --ion-color-facebook-shade: #304778;
      --ion-color-facebook-tint: #4b70be;

      --ion-color-black: #151515;
      --ion-color-black-rgb: 15, 15, 15;
      --ion-color-black-contrast: #ffffff;
      --ion-color-black-contrast-rgb: 255, 255, 255;
      --ion-color-black-shade: #000000;
      --ion-color-black-tint: #444444;
    }

    /*
   * iOS Dark Theme
   * -------------------------------------------
   */
    .ios body {
      --ion-background-color: #000000;
      --ion-background-color-rgb: 0, 0, 0;
      --ion-text-color: #ffffff;
      --ion-text-color-rgb: 255, 255, 255;
      --ion-color-step-50: #0d0d0d;
      --ion-color-step-100: #1a1a1a;
      --ion-color-step-150: #262626;
      --ion-color-step-200: #333333;
      --ion-color-step-250: #404040;
      --ion-color-step-300: #4d4d4d;
      --ion-color-step-350: #595959;
      --ion-color-step-400: #666666;
      --ion-color-step-450: #737373;
      --ion-color-step-500: #808080;
      --ion-color-step-550: #8c8c8c;
      --ion-color-step-600: #999999;
      --ion-color-step-650: #a6a6a6;
      --ion-color-step-700: #b3b3b3;
      --ion-color-step-750: #bfbfbf;
      --ion-color-step-800: #cccccc;
      --ion-color-step-850: #d9d9d9;
      --ion-color-step-900: #e6e6e6;
      --ion-color-step-950: #f2f2f2;
      --ion-toolbar-background: #21202e !important;
      --ion-item-background: #1c1c1c !important;
      --ion-item-background-activated: #313131 !important;
    }

    /*
   * Material Design Dark Theme
   * -------------------------------------------
   */
    .md body {
      --ion-background-color: #121212;
      --ion-background-color-rgb: 18, 18, 18;
      --ion-text-color: #ffffff;
      --ion-text-color-rgb: 255, 255, 255;
      --ion-border-color: #222222;
      --ion-color-step-50: #1e1e1e;
      --ion-color-step-100: #2a2a2a;
      --ion-color-step-150: #363636;
      --ion-color-step-200: #414141;
      --ion-color-step-250: #4d4d4d;
      --ion-color-step-300: #595959;
      --ion-color-step-350: #656565;
      --ion-color-step-400: #717171;
      --ion-color-step-450: #7d7d7d;
      --ion-color-step-500: #898989;
      --ion-color-step-550: #949494;
      --ion-color-step-600: #a0a0a0;
      --ion-color-step-650: #acacac;
      --ion-color-step-700: #b8b8b8;
      --ion-color-step-750: #c4c4c4;
      --ion-color-step-800: #d0d0d0;
      --ion-color-step-850: #dbdbdb;
      --ion-color-step-900: #e7e7e7;
      --ion-color-step-950: #f5f5f5;
      --ion-item-background: #1A1B1E;
    }

    ion-title.title-large {
      --color: white;
    }
  }
}

html.dark {
  --pipeline-sidebar-background-color: #161922;
  --ion-item-background: #161922;
  --ion-background-color: #161922;

  img.inverted {
    filter: invert(0%) !important;
  }

  ion-content.ion-color-dark .post-page a,
  ion-content.ion-color-primary .post-page a {
    color: #000;
  }

  /*
 * Dark Colors
 * -------------------------------------------
 */
  body {
    --ion-color-primary: #A56B3D !important;
    --ion-color-primary-rgb: 165, 107, 61 !important;
    --ion-color-primary-contrast: #fff !important;
    --ion-color-primary-contrast-rgb: 255, 255, 255 !important;
    --ion-color-primary-shade: #845732 !important;
    --ion-color-primary-tint: #ba7e4c !important;
    --ion-color-secondary: #FFFF00;
    --ion-color-secondary-rgb: 225, 225, 0;
    --ion-color-secondary-contrast: #000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #c8c805;
    --ion-color-secondary-tint: #fbfb71;
    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;
    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;
    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;
    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;
    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;
    --ion-color-medium: #313131;
    --ion-color-medium-rgb: 65, 65, 65;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #212121;
    --ion-color-medium-tint: #373737;
    --ion-color-light: #101113;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
    --ion-color-white: #ffffff;
    --ion-color-white-rgb: 255, 255, 255;
    --ion-color-white-contrast: #000000;
    --ion-color-white-contrast-rgb: 0, 0, 0;
    --ion-color-white-shade: #808289;
    --ion-color-white-tint: #9d9fa6;
    --ion-color-facebook: #3b5998;
    --ion-color-facebook-rgb: 59, 89, 152;
    --ion-color-facebook-contrast: #ffffff;
    --ion-color-facebook-contrast-rgb: 255, 255, 255;
    --ion-color-facebook-shade: #304778;
    --ion-color-facebook-tint: #4b70be;

    --ion-color-black: #151515;
    --ion-color-black-rgb: 15, 15, 15;
    --ion-color-black-contrast: #ffffff;
    --ion-color-black-contrast-rgb: 255, 255, 255;
    --ion-color-black-shade: #000000;
    --ion-color-black-tint: #444444;
  }

  /*
  
 * iOS Dark Theme
 * -------------------------------------------
 */
  &.ios body {
    --ion-background-color: #21202e;
    --ion-background-color-rgb: 0, 0, 0;
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;
    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;
    --ion-toolbar-background: #21202e !important;
    --ion-item-background: #161922 !important;
    --ion-item-background-activated: #313131 !important;
  }

  /*
 * Material Design Dark Theme
 * -------------------------------------------
 */
  &.md body {
    --ion-background-color: #21202e;
    --ion-background-color-rgb: 18, 18, 18;
    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;
    --ion-border-color: #222222;
    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f5f5f5;
    --ion-item-background: #161922;
  }

  ion-title.title-large {
    --color: white;
  }

  &.dark,
  &.dark.md,
  &.dark.ios,
  &.dark_pipeline,
  &.dark_pipeline.md,
  &.dark_pipeline.ios,
  &.dark_pipeline body,
  &.dark_pipeline.md body,
  &.dark_pipeline.ios body {
    --ion-background-color: #161922 !important;
    --ion-background-color-rgb: 22, 25, 34 !important;
    --ion-color-base: #21202e !important;
    --ion-color-light: #21202e !important;
    --ion-item-background: #161922 !important;
    --ion-item-background-rgb: 22, 25, 34 !important;
    --ion-card-background: #161922 !important;
    --ion-card-background-rgb: 22, 25, 34 !important;
    --ion-toolbar-background: #21202e !important;
  }

}

.md {
  --ion-statusbar-padding: 40px;

  :root {
    --ion-statusbar-padding: 40px;
  }
  
}