html {
    
    app-login {

        .login-style-helper {
            content: '';
            position: fixed;
            top: 0px;
            left: 0px;
            background-size: contain;
            z-index: 9;
            background-repeat: no-repeat;
            background-position: top left;
    
            @media screen and (max-width: 1279px) {
                position: absolute;
            }
    
            &.a {
                width: 45vw;
                height: 60vh;
                background-image: url(../../assets/img/getgenius/light/circle_bg.webp);
    
                @media screen and (max-width: 1279px) {
                    z-index: 0;
                    width: 95vw;
                }
            }
    
            &.b {
                width: 200px;
                height: 200px;
                left: calc(100% - 100px);
                top: 32.5%;
                background-position: right center;
                background-image: url(../../assets/img/getgenius/light/cube.webp);
                z-index: 8;
    
                @media screen and (max-width: 1279px) {
                    top: 85%;
                }
            }
    
            &.c {
                left: 0%;
                top: 37.5%;
                width: 45vw;
                height: 65vh;
                background-image: url(../../assets/img/getgenius/light/imac_visual.webp);
    
                @media screen and (max-width: 1279px) {
                    top: 21%;
                    left: 0px;
                    width: 60%;
                    right: 0px;
                    margin: auto;
                    display: block;
                    background-position: 70% top;
                    background-size: contain;
                    height: 20vh;
                }

                @media screen and (max-width: 768px) {
                    top: 27%;
                    background-position: right top;
                    left: 35%;
                }

            }
    
        }
    
        .curve {
            display: none !important;
        }
    
        .form {
            position: relative;
            z-index: 9;
    
            &::before {
                display: none !important;
            }
    
            @media screen and (max-width: 1279px) {
                .legal-info-text {
                    margin-top: 30px !important;
                }
            }
    
            @media screen and (max-width: 768px) {
                ion-card {
                    margin-top: 30px;
                    margin-bottom: 30px;
                }
            }

            form {
                max-width: 450px;
                margin: auto;
                display: block;
    
                @media screen and (max-width: 1279px) {
                    margin-top: 22.5vh;
                }
    
                ion-list {
                    > ion-item {
                        margin-top: 15px;
                        margin-bottom: 15px;
                        overflow: hidden;
                        background: var(--ion-item-background);
                        --background: var(--ion-item-background);
                        border: 3px solid rgba(255,255,255,0.25) !important;
                        --border-radius: 20px !important;
                        border-radius: 20px !important;
                    }
                }
            }
        
        }
    
    }

    &.light {
        app-login {
            @media screen and (max-width: 1279px) {
                h1.headline {
                    --color: #fff;
                    color: #fff;
                }
            }
        }
    }

    &.dark {
        app-login {
            .login-style-helper.a {
                background-image: url(../../assets/img/getgenius/dark/circle_bg.webp);
            }
            .login-style-helper.b {
                background-image: url(../../assets/img/getgenius/dark/cube.webp);
            }
            .login-style-helper.c {
                background-image: url(../../assets/img/getgenius/dark/imac_visual.webp);
            }
        }
    }

}