@use "node_modules/driver.js/dist/driver.css";

.introjs-overlay {
  opacity: 0.25 !important;
}

.introjs-helperLayer {
  border: 1px solid rgba(255, 255, 255, 0.35);
  border-radius: 25px;
  background: transparent !important;
}

.introjs-button:focus,
.introjs-button:active {
  border-width: 0px !important;
}

.introjs-tooltip {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding: 25px !important;
}

.introjs-tooltipbuttons {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  margin-left: -25px;
  margin-right: -25px;
  padding: 25px;
  text-align: center;
}

.introjs-tooltipbuttons,
.introjs-tooltip {
  background: var(--ion-color-primary) !important;
  background-color: var(--ion-color-primary) !important;
}

.introjs-arrow.top {
  border-bottom-color: var(--ion-color-primary) !important;
}

.introjs-arrow.bottom {
  border-top-color: var(--ion-color-primary) !important;
}

.introjs-arrow.left {
  border-right-color: var(--ion-color-primary) !important;
  margin-top: 13px;
}

.introjs-arrow.right {
  border-left-color: var(--ion-color-primary) !important;
  margin-top: 13px;
}

.introjs-helperNumberLayer {
  display: none !important;
}

.introjs-button {
  text-shadow: 0px 0px 0px !important;
  padding: 10px 15px !important;
  border-width: 0px !important;
  border-radius: 25px !important;
  margin-left: 0px !important;
  margin-right: 10px !important;
}

.introjs-button:not(:focus):not(:active) {
  color: #434a54 !important;
}

.introjs-button.introjs-disabled:not(:focus):not(:active) {
  color: #fff !important;
}
